body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  padding: 0px 20px;
}

.container {
  align-items: flex-start;
  padding: 40px 0 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

header img {
  max-width: 300px;
}

header h1 {
  margin: -10px 0 10px 0;
  color: #FFF;
  font-size: 28px;
  font-weight: normal;
}
nav {
  width: 100%;
  display: flex;
  justify-content: center;
}

nav a {
  color: #FFF;
  text-decoration: none;
  margin: 10px;
}

nav a i {
  font-size: 30px;
}

.link-card {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.link-card:last-of-type {
  margin-bottom: 50px;
}

.link-card img {
  width: 60px;
  height: 60px;
}

.link-card div {
  flex-grow: 1;
  padding: 0 10px;
}

.link-card h2, .link-card p {
  margin: 0;
}

.link-card h2 {
  font-size: 16px;
  font-weight: 400;
}

.link-card p {
  font-size: 14px;
  color: #777;
}

.cta-button {
  background-color: transparent;
  box-shadow: 1px 1px 7px 0px rgb(0 0 0 / 5%);
  color: #1e1e1e;
  padding: 10px;
  border: 1px solid #cfcdcd;
  border-radius:5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
  -ms-border-radius:5px;
  -o-border-radius:5px;
}

#mobile_deeplink {
  margin: 0 auto;
}

footer {
  position: fixed;
  z-index: 2;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  padding: 10px 20px;
  background: radial-gradient(ellipse at top, #1b2735 0%, #090a0f 100%);
}

footer p {
  margin: 0;
  font-size: 12px;
}

footer a {
  margin-left: 10px;
  color: #FFF;
  text-decoration: none;
}

header, main, footer {
  width: 100%;
}

@media (min-width: 600px) {

  .app {
    padding: 0px 60px;
  }

  .link-card {
    margin: 15px 0;
    padding: 15px;
  }

  .link-card h2 {
    font-size: 20px;
  }

  .link-card p {
    font-size: 16px;
  }

  footer a {
    margin-left: 15px;
  }
}

.iframe-container {
  margin-top: 10px;
  background-color: #f9f9f9;
  overflow: hidden;
  transition: all 0.5s ease;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.link-card.show-iframe {
  flex-direction: column;
}

.link-card.show-iframe .iframe-container iframe {
  display: block;
}

.link-card-content {
  display: flex;
  align-items: center;
}

.iframe-container iframe {
  width: 100%;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .link-card {
      background-color: #101013;
      color: white;
  }
  .link-card p {
    color: #bfbfbf;
  }
  .cta-button {
    background-color: transparent;
    color: white;
  }
}